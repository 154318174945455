import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="bg-blue-300 text-center py-4">
            <p className="text-black font-halo-dek  ">
                ©2024
            </p>
        </footer>
    );
};

export default Footer;
