import React, {useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import gifImage from '../assets/ducks/1.gif';
import gifImage2 from '../assets/ducks/2.gif';
import telesocial from '../assets/ducks/telegram.gif';
import xsocial from '../assets/ducks/x.gif';
import button from '../assets/button.svg';
import {FaRegCopy, FaTelegramPlane} from "react-icons/fa";
import {FaX} from "react-icons/fa6";

const HeaderHero: React.FC = () => {
    // Create refs for the elements you want to animate
    const heroTextRef = useRef<HTMLHeadingElement>(null);
    const heroImgRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        // GSAP animation using refs
        if (heroTextRef.current && heroImgRef.current) {
            gsap.from(heroTextRef.current, {duration: 1, y: -50, opacity: 1, ease: 'bounce'});
            gsap.from(heroImgRef.current, {duration: 1.5, x: -100, opacity: 1, ease: 'power4.out'});
        }
    }, []);
    const handleCopy = () => {
        const textToCopy =
            "CtJ53ohrFTCBJh7WsoLYJumk48ZDZfajXxyeyE7pump"; // Text to copy
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert("Copied to clipboard!");
        }).catch(() => {
            alert("Failed to copy!");
        });
    };

    const telegramRedirect = () => {
        window.open("https://t.me/ducksolchat", "_blank", "noopener,noreferrer");
    };
    const xRedirect = () => {
        window.open("https://x.com/capoocto", "_blank", "noopener,noreferrer");
    };
    const buy = () => {
        window.open("https://raydium.io/swap/?inputMint=sol&outputMint=CtJ53ohrFTCBJh7WsoLYJumk48ZDZfajXxyeyE7pump", "_blank", "noopener,noreferrer");
    };
    const dex = () => {
        window.open("https://www.dextools.io/app/en/solana/pair-explorer/Ar6osBjfJgfXTJKtN92g48NQvkiSYNWdtaxx1hqJan36?t=1734218103477", "_blank", "noopener,noreferrer");
    };


    return (
        <div className="bg-blue-300 relative h-[90vh] p-4 ">
            {/* Header */}
            <header className="w-full text-blue-950 flex justify-end items-center p-4">
                {/*<h1 className="font-petit-cochon text-3xl font-bold">DUCK</h1>*/}
                {/*<img*/}
                {/*    src={logo}*/}
                {/*    alt="Duck"*/}
                {/*    ref={heroImgRef}*/}
                {/*    className="w-16 "*/}

                {/*/>*/}
                <nav className="flex space-x-4">
                    <button className="font-black" onClick={telegramRedirect}>Telegram

                    </button>
                    <button className="font-black" onClick={xRedirect}>X
                    </button>
                </nav>
            </header>

            {/* Hero Section */}
            <section className="text-center h-full mb-10">
                <div className="flex lg:flex-row flex-col items-center justify-center">
                    <div className="mt-4  z-20 relative">
                        <h1 className="font-halo-dek tracking-widest text-7xl font-bold lg:absolute flex-initial left-[20%] text-white text-stroke-3">$CAPOO
                            ON
                            SOLANA</h1>
                        <img
                            src={gifImage}
                            alt="Pepe Character"
                            ref={heroImgRef}
                            className="mx-auto "

                        />
                    </div>
                    <div className="">
                        <div className="relative flex flex-col items-end">

                            <div className="relative">
                                <button onClick={buy}
                                        className="mr-2 relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-blue-500 text-black font-bold py-2 px-8">
                                    BUY NOW
                                </button>
                                <button onClick={dex}
                                        className="mr-2 relative font-halo-dek rounded transform skew-x-[10deg] z-10 bg-blue-500 text-black font-bold py-2 px-8">
                                    Dextools
                                </button>
                            </div>

                            <div
                                className=" bg-blue-100  rounded font-medium py-2 px-4 flex items-center shadow-md transform skew-x-[10deg]">
                                <span className="flex-1 font-halo-dek whitespace-normal lg:whitespace-nowrap">CA: CtJ53ohrFTCBJh7WsoLYJumk48ZDZfajXxyeyE7pump</span>

                                <button className="ml-2" onClick={handleCopy}>
                                    <FaRegCopy className="w-6 h-6"/>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>


                <div>
                    <img
                        src={gifImage2}
                        alt="Pepe Character"
                        className="absolute bottom-[-10px] -left-[5%] z-20 rotate-[40deg]"
                        style={{width: '15%'}}
                    />
                </div>
            </section>

            <div className=" rotate-[-3deg] h-16 bg-blue-300 absolute bottom-0 left-[-4%] w-[110%]"></div>
            <div className=" rotate-[-3deg] h-16 bg-blue-200 absolute bottom-[-104px] left-[-4%] w-[110%]"></div>
        </div>

    );
};

export default HeaderHero;
